import React from 'react'
import styled from '@emotion/styled'
import { css } from '@emotion/core'
import { aspectRatio, aspectRatioChild } from '@/utils/helpers'

const ExtensionIcon = props => (
  <Root {...props}>
    <Svg size={props.size} viewBox="0 0 81 78" fill="none" xmlns="http://www.w3.org/2000/svg">
      <mask id="SVG_Contact_Iconpath-1-inside-1" fill="white">
        <path fillRule="evenodd" clipRule="evenodd" d="M5 10C2.23858 10 0 12.2386 0 15V59C0 61.7614 2.23858 64 5 64H9.56678L17.5018 78L25.4335 64H76C78.7614 64 81 61.7614 81 59V15C81 12.2386 78.7614 10 76 10H5Z"/>
      </mask>
      <path fillRule="evenodd" clipRule="evenodd" d="M5 10C2.23858 10 0 12.2386 0 15V59C0 61.7614 2.23858 64 5 64H9.56678L17.5018 78L25.4335 64H76C78.7614 64 81 61.7614 81 59V15C81 12.2386 78.7614 10 76 10H5Z" fill="white"/>
      <path d="M9.56678 64L13.9167 61.5345L12.4801 59H9.56678V64ZM17.5018 78L13.1519 80.4655L17.5027 88.1417L21.8521 80.4647L17.5018 78ZM25.4335 64V59H22.5195L21.0831 61.5353L25.4335 64ZM5 15V5C-0.522847 5 -5 9.47715 -5 15H5ZM5 59V15H-5V59H5ZM5 59H5H-5C-5 64.5229 -0.522842 69 5 69V59ZM9.56678 59H5V69H9.56678V59ZM21.8517 75.5345L13.9167 61.5345L5.2169 66.4655L13.1519 80.4655L21.8517 75.5345ZM21.0831 61.5353L13.1514 75.5353L21.8521 80.4647L29.7838 66.4647L21.0831 61.5353ZM76 59H25.4335V69H76V59ZM76 59V69C81.5228 69 86 64.5229 86 59H76ZM76 15V59H86V15H76ZM76 15H86C86 9.47715 81.5228 5 76 5V15ZM5 15H76V5H5V15Z" fill="#47B347" mask="url(#SVG_Contact_Iconpath-1-inside-1)"/>
      <path d="M50.9937 46.9441C50.3497 46.4401 49.8177 45.9921 49.1737 45.3761L48.3897 44.6201C49.5377 43.2761 49.9857 42.5761 50.4897 41.3161C51.0777 39.7481 51.3857 38.0681 51.3857 36.1361C51.3857 29.1081 47.3257 24.6001 40.9977 24.6001C34.6417 24.6001 30.4697 29.2201 30.4697 36.2761C30.4697 39.8601 31.5617 42.9121 33.6337 45.0121C35.4537 46.8601 37.9457 47.8121 40.9137 47.8121C42.9577 47.8121 44.4417 47.4201 46.0657 46.4961C47.9137 48.3441 48.1657 48.5961 48.7257 49.3241L50.9937 46.9441ZM41.2497 42.0441C42.0617 42.6601 42.6217 43.1641 43.4897 44.0041C42.7057 44.3961 41.9217 44.5641 40.9697 44.5641C36.9377 44.5641 34.3337 41.2881 34.3337 36.2201C34.3337 33.3361 35.1177 31.0121 36.5737 29.5561C37.7217 28.4361 39.2057 27.8481 40.9137 27.8481C42.6217 27.8481 44.1057 28.4361 45.2257 29.5561C46.6817 31.0121 47.4657 33.3081 47.4657 36.0801C47.4657 38.8801 46.9617 40.5601 45.7297 42.0721C44.5817 40.9521 44.0217 40.3921 43.5457 39.8041L41.2497 42.0441Z" fill="#47B347"/>
    </Svg>
  </Root>
)

const Root = styled.div`
  ${props => props.size === 'contain' ? null : aspectRatio(81, 78)};
`

const Svg = styled.svg`
  ${props => props.size === 'contain'
    ? css`
      width: 100%;
      height: 100%;
    `
    : aspectRatioChild()};
`

export default ExtensionIcon

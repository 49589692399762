import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import { StaticQuery, graphql } from 'gatsby'

function SEO ({ description, lang, meta, keywords, title, ogType, image, location, url }) {
  return (
    <StaticQuery
      query={detailsQuery}
      render={data => {
        const siteUrl = data.site.siteMetadata.siteUrl
        const metaDescription = description === ''
          ? description
          : description || data.site.siteMetadata.description
        const metaKeywords = keywords || data.site.siteMetadata.keywords
        const pageTitle = title ? `${title} | ${data.site.siteMetadata.title}` : data.site.siteMetadata.title
        return (
          <Helmet
            htmlAttributes={{
              lang
            }}
            title={title || data.site.siteMetadata.title}
            titleTemplate={title ? `%s | ${data.site.siteMetadata.title}` : `%s`}
            link={[
              location ? {
                rel: `canonical`,
                href: `${siteUrl}${location.pathname}`
              } : {}
            ]}
            meta={[
              {
                name: `description`,
                content: metaDescription
              },
              {
                property: 'og:url',
                content: location ? `${siteUrl}${location.pathname}` : siteUrl
              },
              {
                property: `og:title`,
                content: pageTitle
              },
              {
                property: `og:description`,
                content: metaDescription
              },
              {
                property: `og:type`,
                content: ogType || `article`
              },
              {
                property: `og:image`,
                content: image || `${siteUrl}${data.site.siteMetadata.image}`
              },
              {
                name: `twitter:card`,
                content: `summary_large_image`
              },
              {
                name: `twitter:creator`,
                content: data.site.siteMetadata.twitterCreator
              },
              {
                name: `twitter:title`,
                content: pageTitle
              },
              {
                name: `twitter:description`,
                content: metaDescription
              },
              {
                name: `twitter:image`,
                content: image || `${siteUrl}${data.site.siteMetadata.image}`
              }
            ]
              .concat(
                metaKeywords.length > 0
                  ? {
                    name: `keywords`,
                    content: metaKeywords.join(`, `)
                  }
                  : []
              )
              .concat(meta)
              .filter(meta => {
                return meta.content
              })
            }
          />
        )
      }}
    />
  )
}

SEO.defaultProps = {
  lang: `ja`,
  meta: [],
  keywords: []
}

SEO.propTypes = {
  siteUrl: PropTypes.string,
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.array,
  keywords: PropTypes.arrayOf(PropTypes.string),
  title: PropTypes.string
}

export default SEO

const detailsQuery = graphql`
  query DefaultSEOQuery {
    site {
      siteMetadata {
        siteUrl
        title
        description
        keywords
        image
        company {
          name
          logo
          siteUrl
        }
      }
    }
  }
`

import React from 'react'
import styled from '@emotion/styled'
import { css } from '@emotion/core'
import { aspectRatio, aspectRatioChild } from '@/utils/helpers'

const DownloadIcon = props => (
  <Root {...props}>
    <Svg size={props.size} viewBox="0 0 101 63" fill="none" xmlns="http://www.w3.org/2000/svg">
      <mask id="SVG_DownloadIcon_path-1-inside-1" fill="white">
        <path fillRule="evenodd" clipRule="evenodd" d="M66.7011 12.647C68.1711 12.2592 69.7146 12.0527 71.3063 12.0527C80.4156 12.0527 87.9451 18.8169 89.1459 27.5965C95.9756 30.1546 100.837 36.7418 100.837 44.4645C100.837 54.4093 92.7754 62.4711 82.8306 62.4711H18.0067C8.06186 62.4711 0 54.4093 0 44.4645C0 35.714 6.24174 28.4214 14.5158 26.796C15.8032 12.0754 28.161 0.52832 43.2158 0.52832C52.9059 0.52832 61.4786 5.31216 66.7011 12.647Z"/>
      </mask>
      <path fillRule="evenodd" clipRule="evenodd" d="M66.7011 12.647C68.1711 12.2592 69.7146 12.0527 71.3063 12.0527C80.4156 12.0527 87.9451 18.8169 89.1459 27.5965C95.9756 30.1546 100.837 36.7418 100.837 44.4645C100.837 54.4093 92.7754 62.4711 82.8306 62.4711H18.0067C8.06186 62.4711 0 54.4093 0 44.4645C0 35.714 6.24174 28.4214 14.5158 26.796C15.8032 12.0754 28.161 0.52832 43.2158 0.52832C52.9059 0.52832 61.4786 5.31216 66.7011 12.647Z" fill="white"/>
      <path d="M66.7011 12.647L62.6281 15.547L64.6334 18.3634L67.9764 17.4816L66.7011 12.647ZM89.1459 27.5965L84.1921 28.274L84.5966 31.2317L87.3921 32.2788L89.1459 27.5965ZM14.5158 26.796L15.4797 31.7022L19.1693 30.9774L19.4968 27.2316L14.5158 26.796ZM67.9764 17.4816C69.0338 17.2027 70.1488 17.0527 71.3063 17.0527V7.0527C69.2804 7.0527 67.3084 7.31577 65.4259 7.81232L67.9764 17.4816ZM71.3063 17.0527C77.8821 17.0527 83.3253 21.9372 84.1921 28.274L94.0998 26.9189C92.5649 15.6966 82.9492 7.0527 71.3063 7.0527V17.0527ZM87.3921 32.2788C92.3332 34.1295 95.8372 38.8941 95.8372 44.4645H105.837C105.837 34.5896 99.6181 26.1797 90.8998 22.9141L87.3921 32.2788ZM95.8372 44.4645C95.8372 51.6479 90.0139 57.4711 82.8306 57.4711V67.4711C95.5368 67.4711 105.837 57.1707 105.837 44.4645H95.8372ZM82.8306 57.4711H18.0067V67.4711H82.8306V57.4711ZM18.0067 57.4711C10.8233 57.4711 5 51.6479 5 44.4645H-5C-5 57.1707 5.30044 67.4711 18.0067 67.4711V57.4711ZM5 44.4645C5 38.1495 9.50551 32.8758 15.4797 31.7022L13.552 21.8898C2.97797 23.967 -5 33.2786 -5 44.4645H5ZM19.4968 27.2316C20.5603 15.071 30.7764 5.52832 43.2158 5.52832V-4.47168C25.5457 -4.47168 11.0461 9.07977 9.53486 26.3604L19.4968 27.2316ZM43.2158 5.52832C51.2202 5.52832 58.3034 9.47319 62.6281 15.547L70.7742 9.74688C64.6538 1.15113 54.5915 -4.47168 43.2158 -4.47168V5.52832Z" fill="#47B347" mask="url(#SVG_DownloadIcon_path-1-inside-1)"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M44.5 34.7778L44.5 25L54.5 25L54.5 34.7778L62 34.7778L49.5 47L37 34.7778L44.5 34.7778V34.7778Z" fill="#47B347"/>
    </Svg>
  </Root>
)

const Root = styled.div`
  ${props => props.size === 'contain' ? null : aspectRatio(101, 63)};
`

const Svg = styled.svg`
  ${props => props.size === 'contain'
    ? css`
      width: 100%;
      height: 100%;
    `
    : aspectRatioChild()};
`

export default DownloadIcon

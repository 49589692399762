import React from 'react'
import { Link } from 'gatsby'
import styled from '@emotion/styled'
import { css } from '@emotion/core'
import ThemeConsumer from '@/hoc/ThemeConsumer'
import { layouts } from '@/utils/preset'
import { mobile, mobileS } from '@/utils/media'
import { mq } from '@/utils/helpers'
import Typography from '@/components/common/Typography'
import IconFont from '@/components/common/Icon'
import LinkButton1 from '@/components/common/LinkButton1'
import DownloadIcon from '@/components/svg/DownloadIcon'
import ContactIcon from '@/components/svg/ContactIcon'

const ConversionLead = props => {
  return (
    <Root {...props}>
      <Container>
        <Head>
          <Title
            variant="h2"
            color="primary.contrast">
            まずはお気軽に<br />ご相談ください
          </Title>
        </Head>
        <Main>
          <Body variant="body1">
            現状の業務内容や経営目標など<br />
            経営コンサルティングの視点からアドバイスを行います。<br />
            相談は無料です。まずは気軽にお問い合わせください。
          </Body>
          <List>
            <List_Item>
              <Icon>
                <IconHead theme={props.theme}>
                  <DownloadIcon css={styles.DownloadIcon} />
                </IconHead>
                <IconMain>
                  <Button component={Link} color="primary" contrast={true} to="/document/" theme={props.theme}>
                    <Button_Icon name="download" />
                    資料ダウンロード
                  </Button>
                </IconMain>
              </Icon>
            </List_Item>
            <List_Item>
              <Icon>
                <IconHead theme={props.theme}>
                  <ContactIcon css={styles.ContactIcon} />
                </IconHead>
                <IconMain>
                  <Button component={Link} color="primary" contrast={true} to="/contact/" theme={props.theme}>
                    <Button_Icon name="chat" css={styles.MobileContactIcon} />
                    お問い合わせ
                  </Button>
                </IconMain>
              </Icon>
            </List_Item>
          </List>
        </Main>
      </Container>
    </Root>
  )
}

const Root = styled(layouts.components.FullWidth)`
  padding: 100px 0 120px;
  @media ${mq.and(mobile)} {
    padding: 48px 0 64px;
  }
  background-color: ${props => props.theme.primary.base};
  color: ${props => props.theme.primary.contrast.base};
`

const Container = styled(layouts.components.Container())`
`

const Head = styled.div``

const Title = styled(Typography)`
  text-align: center;
  br {
    display: none;
  }
  @media ${mq.and(mobile)} {
    br {
      display: initial;
    }
  }
`

const Main = styled.div`
  margin-top: 40px;
  @media ${mq.and(mobile)} {
    margin-top: 32px;
  }
`

const Body = styled(Typography)`
  margin: 0 auto;
  max-width: ${layouts.defs.contentMaxWidth.base}px;
  text-align: center;
  @media ${mq.and(mobile)} {
    text-align: left;
    br {
      display: none;
    }
  }
`

const List = styled.div`
  margin-top: 40px;
  @media ${mq.and(mobile)} {
    margin-top: 32px;
  }
  display: flex;
  justify-content: center;
  @media ${mq.and(mobile)} {
    display: block;
  }
`

const List_Item = styled.div`
  &:not(:first-of-type) {
    margin-left: 40px;
    @media ${mq.and(mobile)} {
      margin-left: 0;
      margin-top: 12px;
    }
  }
`

const Icon = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

const IconHead = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 150px;
  height: 150px;
  border-radius: 50%;
  border: 4px solid currentColor;
  background-color: ${props => props.theme.primary.light};
  font-size: 66px;
  @media ${mq.and(mobile)} {
    display: none;
  }
`

const IconMain = styled.div`
  margin-top: 28px;
  @media ${mq.and(mobile)} {
    margin-top: 0;
  }
  @media ${mq.and(mobileS)} {
    width: 100%;
  }
`

const Button = styled(LinkButton1)`
  width: 300px;
  @media ${mq.and(mobileS)} {
    width: 100%;
  }
`

const styles = {
  DownloadIcon: css`
    width: 100px;
  `,
  ContactIcon: css`
    width: 81px;
  `,
  MobileContactIcon: css`
    @media ${mq.and(mobile)} {
      font-size: 1.5em;
    }
  `
}

const Button_Icon = styled(IconFont)`
  display: none;
  @media ${mq.and(mobile)} {
    display: inline-block;
    font-size: 1.2em;
  }
  margin-right: 12px;
`

export default ThemeConsumer(ConversionLead)

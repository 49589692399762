import path from 'path'
import React, { Fragment } from 'react'
import { connect } from 'react-redux'
import { StaticQuery, graphql } from 'gatsby'
import Breadcrumb from './Breadcrumb'
import LinkingData from '@/components/head/linking-data'
import { flattenRoutes } from '@/utils/routes'

class Container extends React.Component {
  createItems (targetPath, routes, items = []) {
    if (targetPath === '/') {
      return items
    }
    const parentPath = path.resolve(targetPath, '..')
    const route = this.getRoute(parentPath, routes)
    if (route) items.unshift(route)
    return this.createItems(parentPath, routes, items)
  }
  getRoute (targetPath, routes) {
    return routes.find(el => el.path === targetPath || el.path === `${targetPath}/`)
  }
  render () {
    return (
      <StaticQuery
        query={query}
        render={ data => {
          const siteUrl = data.site.siteMetadata.siteUrl
          const routes = flattenRoutes(data.routes.edges[0].node)
          let pagePath = this.props.path
          if (pagePath.substr(-1) !== '/') {
            pagePath = `${pagePath}/`
          }
          const items = this.createItems(pagePath, routes)
          const route = routes.find(route => {
            return route.path === pagePath
          })
          items.push({
            title: this.props.title || route.title,
            path: this.props.path
          })
          return (
            <Fragment>
              <LinkingData json={{
                '@type': 'BreadcrumbList',
                'itemListElement': items.map((item, index) => ({
                  '@type': 'ListItem',
                  'position': index + 1,
                  'item': {
                    '@id': `${siteUrl}${item.path}`,
                    'name': item.title
                  }
                }))
              }}/>
              {items.length && items.length > 1 && (
                <Breadcrumb items={items} />
              )}
            </Fragment>
          )
        }}
      />
    )
  }
}

export default connect()(Container)

const query = graphql`
  query BreadcrumbQuery {
    site {
      siteMetadata {
        siteUrl
      }
    }
    routes: allRouteYaml {
      edges {
        node {
          main {
            title
            path
          }
          conversion {
            title
            path
          }
          others {
            title
            childLists {
              title
              path
            }
          }
        }
      }
    }
  }
`

import path from 'path'

export const Corporation = (siteMeta, company) => {
  return {
    '@type': 'Corporation',
    ...company,
    logo: `${siteMeta.siteUrl}/images/icon.png`,
    image: `${siteMeta.siteUrl}/images/ogp.png`,
    url: `${siteMeta.siteUrl}/`
  }
}

export const Organization = (siteMeta) => {
  return {
    '@type': 'Organization',
    name: siteMeta.company.name,
    logo: {
      '@type': 'ImageObject',
      '@id': `${siteMeta.company.siteUrl}#logo`,
      url: siteMeta.company.logo
    },
    sameAs: `${siteMeta.company.siteUrl}/`
  }
}

export const WebSite = (siteMeta) => {
  return {
    '@type': 'WebSite',
    '@id': `${siteMeta.siteUrl}#website`,
    url: `${siteMeta.siteUrl}`,
    name: siteMeta.title,
    publisher: Organization(siteMeta),
    image: {
      '@type': 'ImageObject',
      url: path.join(siteMeta.siteUrl, siteMeta.image)
    }
  }
}

export default {
  Corporation, Organization, WebSite
}

import React from 'react'
import { Link as GatsbyLink } from 'gatsby'
import styled from '@emotion/styled'
import ThemeConsumer from '@/hoc/ThemeConsumer'
import { layouts } from '@/utils/preset'
import { desktop, laptop, mobile } from '@/utils/media'
import { mq } from '@/utils/helpers'

const Root = styled(layouts.components.FullWidth)`
  padding: 10px 0;
  background-color: ${props => props.theme.gray.b};
`

const Container = styled(layouts.components.Container())`
  margin: 0 auto;
`
const List = styled.ol`
  display: flex;
  overflow: hidden;
  @media ${mq.and(desktop, laptop)} {
    padding: 0 40px;
  }
`

const List_Item = styled.li`
  flex-shrink: 0;
  white-space: nowrap;
  display: flex;
  flex-direction: row;
  align-items: center;
  overflow: hidden;
  &:not(:first-of-type):before {
    content: '＞';
    display: inline-block;
    margin: 0 0.5em;
  }

  &:last-of-type {
    flex-shrink: unset;
  }

  font-size: 12px;
  @media ${mq.and(mobile)} {
    font-size: 10px;
  }
`

const Link = styled(GatsbyLink)`
  line-height: 1.2;
`

const Current = styled(Link)`
  font-weight: bold;
  text-decoration: none;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: ${props => props.theme.primary.base};
`.withComponent('span')

const Breadcrumb = props => {
  return (
    <Root className={props.className} theme={props.theme}>
      <Container>
        <List>
          {props.items.map((item, index) => (
            <List_Item key={index}>
              {index === props.items.length - 1
                ? (
                  <Current theme={props.theme}>{item.title}</Current>
                )
                : (
                  <Link to={item.path}>{item.title}</Link>
                )
              }
            </List_Item>
          ))}
        </List>
      </Container>
    </Root>
  )
}
export default ThemeConsumer(Breadcrumb)

import React from 'react'
import styled from '@emotion/styled'
import MuiButton from '@material-ui/core/Button'
import ThemeConsumer from '@/hoc/ThemeConsumer'
import WithStylesProps from '@/hoc/WithStylesProps'
import { fonts } from '@/utils/preset'
import { getThemeColor } from '@/utils/themes'

const LinkButton1 = props => {
  const { theme, type, classes, children, className, color, contrast, ...other } = props
  return (
    <MuiButton
      className={className}
      variant="contained"
      classes={classes}
      {...other}
    >
      { children }
      <Arrow>
        <Arrow_Svg width="8" height="10" viewBox="0 0 8 10" xmlns="http://www.w3.org/2000/svg">
          <path d="M8 5L0.5 9.33013L0.5 0.669872L8 5Z" />
        </Arrow_Svg>
      </Arrow>
    </MuiButton>
  )
}

const getStyles = (props) => {
  const color = props.color || 'text'
  if (props.contrast) {
    return {
      backgroundColor: getThemeColor(color, props.theme, 'contrast'),
      color: getThemeColor(color, props.theme),
      hoverBackgroundColor: getThemeColor(color, props.theme, 'contrastHover')
    }
  }
  return {
    backgroundColor: getThemeColor(color, props.theme),
    color: getThemeColor(color, props.theme, 'contrast'),
    hoverBackgroundColor: getThemeColor(color, props.theme, 'hover')
  }
}

const styles = props => {
  const styles = getStyles(props)
  return {
    root: {
      backgroundColor: styles.backgroundColor,
      color: styles.color,
      height: 70,
      padding: '0 16px',
      fontSize: 16,
      fontWeight: 'bold',
      fontFamily: fonts.base,
      boxShadow: 'none',
      justifyContent: 'center',
      borderRadius: 5,
      '&:hover': {
        textDecoration: 'none',
        backgroundColor: styles.hoverBackgroundColor,
        boxShadow: 'none',
        '@media (hover: none)': {
          backgroundColor: styles.backgroundColor
        },
        '&:disabled': {
          backgroundColor: styles.backgroundColor
        }
      },
      '&:disabled': {
        backgroundColor: styles.backgroundColor,
        color: styles.color,
        opacity: 0.6
      }
    },
    label: {
      position: 'relative'
    }
  }
}

const Arrow = styled.span`
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
`

const Arrow_Svg = styled.svg`
  fill: currentColor;
`

export default ThemeConsumer(WithStylesProps(styles)(LinkButton1))

import React from 'react'
import styled from '@emotion/styled'
import ThemeConsumer from '@/hoc/ThemeConsumer'
import { getThemeColor } from '@/utils/themes'
import Typography from './Typography'

const Tag = props => {
  const { theme, color, type, component, children, ...other } = props
  return (
    <Root
      theme={theme}
      color={color}
      type={type}
      {...other}>
      <Text
        variant="caption2"
        theme={theme}
        color={color}
        type={type}
        component={component}>
        {children}
      </Text>
    </Root>
  )
}

const getColor = props => {
  const color = props.color || 'text'
  return getThemeColor(color, props.theme)
}

const getContrastColor = props => {
  const color = props.color || 'text'
  return getThemeColor(color, props.theme, 'contrast')
}

const Root = styled.div`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  padding: 0 16px;
  height: 22px;
  background-color: ${getColor};
  border-radius: 11px;
  font-weight: bold;
`

const Text = styled(Typography)`
  color: ${getContrastColor};
`

export default ThemeConsumer(Tag)

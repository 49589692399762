import React from 'react'
import styled from '@emotion/styled'
import ThemeConsumer from '@/hoc/ThemeConsumer'
import { desktop, laptop, mobile } from '@/utils/media'
import { mq } from '@/utils/helpers'
import { layouts } from '@/utils/preset'
import Typography from '@/components/common/Typography'
import Tag from '@/components/common/Tag'

const PageHead = props => {
  const { title, description, date, tags, ...others } = props
  let postDate = props.date ? new Date(props.date) : null
  if (postDate) {
    const year = `${postDate.getFullYear()}`
    const month = `${postDate.getMonth() + 1}`
    const date = `${postDate.getDate()}`
    postDate = {
      string: `${year}年${month}月${date}日`,
      datetime: `${year}-${month.padStart(2, 0)}-${date.padStart(2, 0)}`
    }
  }
  return (
    <Root {...others}>
      <Container>
        <Wrapper>
          {tags && tags.length && (
            <Tags>
              {tags && tags.map((tag, index) => (
                <Tags_Item key={index}>
                  <Tag color="gray.a">{tag}</Tag>
                </Tags_Item>
              ))}
            </Tags>
          )}
          <Title variant="h1">{title}</Title>
          {description && (
            <Description variant="body1">{description}</Description>
          )}
          {postDate && (
            <DateTime variant="caption1" component="time" dateTime={postDate.datetime}>{postDate.string}</DateTime>
          )}
        </Wrapper>
      </Container>
    </Root>
  )
}

export default ThemeConsumer(PageHead)

const Root = styled(layouts.components.FullWidth)`
  background-color: ${props => props.theme.primary.base};
  color: ${props => props.theme.primary.contrast.base};
`

const Container = styled(layouts.components.Container())``

const Wrapper = styled.div`
  padding: 36px 0;
  @media ${mq.and(desktop, laptop)} {
    padding: 36px 40px;
  }
`

const Tags = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-top: -8px;
  margin-left: -8px;
  margin-bottom: 8px;
`

const Tags_Item = styled.div`
  margin-top: 8px;
  margin-left: 8px;
  margin-bottom: 8px;
`

const DateTime = styled(Typography)`
  display: block;
  margin-top: 12px;
`

const Title = styled(Typography)`
  max-width: ${layouts.defs.contentMaxWidth.base}px;
`

const Description = styled(Typography)`
  margin-top: 20px;
  max-width: ${layouts.defs.contentMaxWidth.base}px;
  @media ${mq.and(mobile)} {
    margin-top: 16px;
  }
`

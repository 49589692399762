import React from 'react'
import styled from '@emotion/styled'
import ThemeConsumer from '@/hoc/ThemeConsumer'
import { mobileM, mobileS } from '@/utils/media'
import { mq } from '@/utils/helpers'
import Typography from '@/components/common/Typography'

const Headline = props => {
  const { variant, component, lang, theme, color, children, ...other } = props
  const contentProps = {
    variant: variant || 'h2',
    component,
    lang,
    theme,
    color: color || 'gray.e'
  }
  return (
    <Root {...other}>
      <Content {...contentProps}>{children}</Content>
    </Root>
  )
}

const Root = styled.div`
  display: flex;
  justify-content: center;
`

const Content = styled(Typography)`
  position: relative;
  padding-bottom: 18px;
  @media ${mq.and(mobileM, mobileS)} {
    padding-bottom: 12px;
  }
  text-align: center;
  strong {
    font-weight: bold;
  }

  &::after {
    content: '';
    display: block;
    position: absolute;
    left: 0;
    bottom: 0;
    right: 0;
    height: 3px;
    background-color: ${props => props.theme.primary.base};
  }
  @media ${mq.and(mobileM, mobileS)} {
    &::after {
      height: 3px;
    }
  }
`

export default ThemeConsumer(Headline)
